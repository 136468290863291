<template>
  <main id="main">
    <section id="exhibit" class="exhibit">
      <BidChat/>
    </section>
  </main>
</template>

<script>
import inspectionAuctionRequest from '@/stores/inspectionAuctionRequest'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'
import BidChat from '../../components/Chat/BidChatForm'
import useChat from '../../stores/useChat'
export default defineComponent({
  setup() {
    const chatStores = useChat()
    const auctionRequestStore = inspectionAuctionRequest()
    const loadingStore = useLoading()
    return {
      chatStores,
      auctionRequestStore,
      loadingStore
    }
  },
  components : {
    BidChat
  },
  mounted() {
    this.loadingStore.setLoading(true)
    const selectExhibitionItemNo = this.$route.params ? this.$route.params.id : null
    this.chatStores.memberCheck(selectExhibitionItemNo, 'bid')
      .then(() => {
        this.chatStores.reset(selectExhibitionItemNo, 'bid')
        .then(() => {
          this.$nextTick(() => {
            document.getElementById('chatInput')?.focus()
          })
          this.loadingStore.setLoading(false)
        })
        .catch(error => {
          this.loadingStore.setLoading(false)
          this.$router.go(-1)
        })
      })
      .catch(error => {
        this.loadingStore.setLoading(false)
        this.$cookies.remove(this.$define.LOCAL_STORE_LABEL.SESSION_TOKEN)
        this.$cookies.remove(this.$define.LOCAL_STORE_LABEL.MEMBER_NO)
        this.$routerGo(this.$define.PAGE_NAMES.LOGIN_PAGE_NAME)
      })
  }
})
</script>

<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
